<template>
<div class="exhibitor">
    <div v-if="this.isFetching" class="exhibitor-loading">
        <div class="lds-ring">
            <img class="loading-gif" src="@/assets/img/loadingwheel.gif" alt="">
        </div>
    </div>
    <div v-else class="exhibitor">
        <Breadcrumb v-bind:item="this.exhibitor.name"/>

        <!-- <div class="image-banner">
            <img src="../assets/img/background.png" alt="">
        </div> -->
        
        <div class="exhibitor-wrap">
            <div class="col col-imagen">
                <div class="exhibitor-logo">
                    <img v-if="this.exhibitor.logo" :src="exhibitor.logo.fluid.src" alt="exhibitor">
                    <img v-else  :src="exhibitor.mainImage.fluid.src" alt="exhibitor">
                </div>
            </div>

            <div class="col col-info">
                <SectionHeader v-bind:title="this.exhibitor.name"/>

                <div class="exhibitor-grid">

                    <div class="exhibitor-info">
                        <p class="info-name">PHONE</p>
                        <p class="info-value">{{this.exhibitor.telephone}}</p>

                        <p class="info-name">EMAIL</p>
                        <p class="info-value"> <a :href="`mailto:`+ this.exhibitor.email">{{this.exhibitor.email}}</a></p>

                        <p class="info-name">WEBSITE</p>
                        <p class="info-value"><a target="_blank" :href="`http://`+ this.exhibitor.website">{{this.exhibitor.website}}</a></p>
                    
                        <p class="info-name">ADDRESS</p>
                        <p class="info-value">
                            <span>{{this.exhibitor.street}}, </span>
                            <span>{{this.exhibitor.postalCode}}, </span>
                            <span>{{this.exhibitor.city}}, </span>
                            <span>{{this.exhibitor.country}} </span>
                        </p>
                    </div>

                    <div class="exhibitor-desc">
                        <p>{{this.exhibitor.shortDescription}}</p>
                    </div>
                </div>

                <div class="related-products">
                    <h2 class="subtitle">Wines stocked</h2>

                    <div class="related-grid">
                        <div class="product"  v-for="(product, item) in this.products" v-bind:key="item">
                            <div v-if="product.bottleImage" class="col">
                                <img @click="goToProduct(product.slug, product.productId, product.producer.exhibitorId)" :src="product.bottleImage.fluid.src" alt="product">
                            </div>
                            <div v-if="product.bottleImage" class="col">
                                <h2  @click="goToProduct(product.slug, product.productId, product.producer.exhibitorId)" class="product-title">{{product.shortName}}</h2>

                                <p v-if="product.description" class="product-desc">{{shorten(product.description, 130, true)}}</p>
                                <a @click="goToProduct(product.slug, product.productId, product.producer.exhibitorId)">READ MORE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


</template>
<script>
import Breadcrumb from '@/components/snippets/Breadcrumb.vue'
import SectionHeader from '@/components/snippets/SectionHeader.vue'
import gql from 'graphql-tag'


export default {
    name: 'exhibitor',

    components: { 
        SectionHeader,
        Breadcrumb
    },

    data() {
        return {
            title: 'Exhibitor Name',
            isFetching: true,
            exhibitorId: this.$route.params.exhibitorId,
            exhibitor: [],
            products: []
        }
    },

    mounted() {
        this.exhibitorId = this.$route.params.exhibitorId;
    },

    methods: {
        goToProduct(slug, productId, exhibitorId) {
            this.$router.push({name:'product', params:{slug:slug, productId:productId, exhibitorId:exhibitorId}})
        },
        shorten(str, n, useWordBoundary) {
            if (str.length <= n) { return str; }
            const subString = str.substr(0, n-1);
            return (useWordBoundary 
                ? subString.substr(0, subString.lastIndexOf(" ")) 
                : subString) + "..."
        },
    },

    apollo: {

        exhibitor: {
            query: gql`query ($exhibitorId: ID!) {
                event(eventId: "5f842ed57507225ccb782eb4") {
                    exhibitor (exhibitorId:$exhibitorId) {
                        mainImage {
                            fluid(maxWidth: 520, maxHeight:520, crop: FILL){
                                src
                            }
                        }

                        logo {
                            fluid(maxWidth: 520, maxHeight:520, crop: PAD){
                                src
                            }
                        }

                        name
                        telephone
                        website
                        street
                        city
                        country
                        postalCode
                        email
                        shortDescription
                        exhibitorId

                        products {
                            nodes {
                                bottleImage {
                                    fluid(maxWidth: 420, maxHeight:560, crop: PAD){
                                        src
                                    }
                                }
                                shortName
                                slug
                                description
                                productId

                                producer {
                                    exhibitorId
                                }
                            }
                        }
                    }
                }
            }`,

            variables() {
                return {
                    exhibitorId: this.exhibitorId
                }
            },

            result ({ data, loading }) {
                if (!loading) {
                    console.log("done");

                    var productsArray = data.event.exhibitor.products.nodes
                    productsArray = productsArray.filter(function( item ) {
                        let img = item.bottleImage;
                        
                        if (img !== null) {
                            return true
                        }
                    });
                    
                    this.exhibitor  = data.event.exhibitor
                    this.products   = productsArray
                    this.isFetching = false;
                }
            },
        }
    }
}
</script>